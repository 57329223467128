module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NWCPDK4","includeInDevelopment":false,"enableWebVitalsTracking":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Impressto","short_name":"Impressto","start_url":"/","lang":"fr-FR","background_color":"#fab637","theme_color":"#fab637","display":"standalone","legacy":false,"icon":"./node_modules/@printdeal/brand/impressto/images/production-android-chrome-512x512.png","localize":[{"start_url":"/","lang":"fr"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e91eb6ab0e4838ef822411feba47f2a8"},
    },{
      plugin: require('../plugins/gatsby-plugin-auto-refresh/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-error-management/gatsby-browser.ts'),
      options: {"plugins":[],"enabled":true,"environment":"production","url":"https://b9ea2f3344d347e5b932f22c9ccb1f92@o50002.ingest.sentry.io/1416261"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
